"use client";
import CategoryJobCard from "@/app/_components/share/CategoryJobCard";
import JobCardPlaceholder from "@/app/_components/share/JobCardPlaceholder";
import { JOB_INFORMATION_DETAIL_PATH } from "@/services/constants/routerPath";
import { Box, Center, Stack } from "@chakra-ui/react";
import { get } from "lodash";
import Link from "next/link";
import { Autoplay, Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
export default function JobSlider({ vipCategory, topCategory }) {
  return (
    <Stack w="full" gap={6}>
      <Swiper
        style={{ width: "100%" }}
        slidesPerView={1}
        spaceBetween={16}
        modules={[Autoplay, Navigation]}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{ enabled: true }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            navigation: { enabled: true },
          },
          480: {
            slidesPerView: 1,
            navigation: { enabled: true },
          },
          768: {
            slidesPerView: 2,
            navigation: { enabled: true },
          },
          1024: {
            slidesPerView: 3,
            navigation: { enabled: true },
          },
        }}
      >
        {get(vipCategory, "adsJobs").map((categoryJobItem, index) => {
          const { job } = categoryJobItem;

          return (
            <SwiperSlide key={index}>
              <CategoryJobCard
                key={job._id}
                id={job._id}
                corporateId={get(job, "corporate._id")}
                imageUrl={
                  get(job, "recruitmentNotice[0].url") ||
                  get(job, "corporate.logo.url")
                }
                logoUrl={
                  get(job, "corporate.logo.url") || get(job, "corporate.logo")
                }
                subTitle={job.companyName}
                title={job.recruitmentName}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        style={{ width: "100%" }}
        grid={{ rows: 2, fill: "row" }}
        slidesPerView={4}
        spaceBetween={4}
        modules={[Autoplay, Grid, Navigation]}
        loop={true}
        loopAddBlankSlides={false}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={{ enabled: true }}
        breakpoints={{
          320: {
            slidesPerView: 2,
            navigation: { enabled: true },
          },
          480: {
            slidesPerView: 2,
            navigation: { enabled: true },
          },
          768: {
            slidesPerView: 3,
            navigation: { enabled: true },
          },
          1280: {
            slidesPerView: 4,
            navigation: { enabled: true },
          },
        }}
      >
        {get(topCategory, "adsJobs").map((categoryJobItem, index) => {
          const { job } = categoryJobItem;
          const isPlaceholder = !job._id;
          const image =
            get(job, "recruitmentNotice[0].url") ??
            get(job, "corporate.logo.url");
          return (
            <SwiperSlide key={index} h="full">
              {isPlaceholder ? (
                <Center aspectRatio={["450 / 300", "450 / 300", "380 / 200"]}>
                  <JobCardPlaceholder minH={0} />
                </Center>
              ) : (
                <Link href={JOB_INFORMATION_DETAIL_PATH + get(job, "_id")}>
                  <Box
                    // aspectRatio={["450 / 300", "450 / 300", "380 / 200"]}
                    height={{ base: "300px", md: "200px" }}
                    rounded="xl"
                    bgPosition="center"
                    bgSize="cover"
                    bgColor="faded"
                    bgImage={image}
                  ></Box>
                </Link>
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Stack>
  );
}
