"use client";
import { Box, HStack, Skeleton } from "@chakra-ui/react";

import DefaultBannerImage from "@/app/_components/share/DefaultBannerImage";
import { getAdsBannerList, getHomepageAdsBannerList } from "@/services/api/ads";
import { AdsBannerPosition, BannerPage } from "@/services/constants/global";
import { get, range } from "lodash";
import Link from "next/link";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const showBanner = (banner) => {
  if (!get(banner, "image")) return <DefaultBannerImage />;
  return (
    <Link href={get(banner, "link")} target="_blank" h="full">
      <Box
        w="full"
        h="full"
        bgImage={get(banner, "image.url") ?? get(banner, "image.location")}
        bgPosition="center"
        bgSize="cover"
        rounded="lg"
        overflow="hidden"
      ></Box>
    </Link>
  );
};

export default function BannerAdsGrid({ page = "" }) {
  const { isLoading, data } = useQuery(
    ["banner", page],
    page === BannerPage.INTRODUCTION_PAGE
      ? async () => getAdsBannerList(AdsBannerPosition.LANDING)
      : async () => getHomepageAdsBannerList()
  );

  const BannerGridLayout = useMemo(() => {
    const isIntroductionPage = page === BannerPage.INTRODUCTION_PAGE;
    if (isLoading || !data) return;
    <HStack w="full">
      {range(isIntroductionPage ? 2 : 3).map((index) => (
        <Skeleton key={index} rounded="lg" w="full" h={100} />
      ))}
    </HStack>;

    return (
      <Swiper
        style={{
          width: "100%",
          // height: page === BannerPage.INTRODUCTION_PAGE ? 100 : 150,
        }}
        slidesPerView={1}
        spaceBetween={16}
        modules={[Autoplay]}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 2,
            // slidesPerView: page === BannerPage.INTRODUCTION_PAGE ? 2 : 3,
          },
        }}
      >
        {data.map((ads) => (
          <SwiperSlide key={ads._id}>
            <Box
              border="1px solid"
              borderColor="faded"
              key={ads._id}
              rounded="md"
              height={{ base: "150px", md: "100px" }}
              // aspectRatio={
              //   page === BannerPage.INTRODUCTION_PAGE
              //     ? "750 / 100"
              //     : "500 / 150"
              // }
              overflow="hidden"
            >
              {showBanner(ads)}
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }, [isLoading, data]);

  return (
    <Box position="relative" w="full" py={{ base: 6, md: 10 }}>
      {BannerGridLayout}
    </Box>
  );
}
